import React from "react";
import Placeholder from "../../../core/Components/Placeholder";

function SitecoreFormContainer({ rendering, fields }) {
  const updatedRendering = {
    ...rendering,
    fields: {
      ...rendering.fields,
      ...fields.placeholderData,
    },
  };

  return (
    <div
      id={rendering.fields.id.value}
      className={rendering.fields.class.value}
    >
      <Placeholder
        name="sitecore-form-container"
        rendering={updatedRendering}
        placeholderData={updatedRendering.fields}
      />
    </div>
  );
}

export default SitecoreFormContainer;
